// ./services/DeckService.js

// Function to load the deck box from local storage
const getDeckBox = () => {
    const savedDecks = localStorage.getItem("scdecks");
    return savedDecks ? JSON.parse(savedDecks) : [];
  };
  
  // Function to save the deck box to local storage
  const saveDeckBox = (deckBox) => {
    localStorage.setItem("scdecks", JSON.stringify(deckBox));
  };
  
  // Function to get a specific deck by its name
  const getDeckByName = (deckName) => {
    const deckBox = getDeckBox();
    return deckBox.find((deck) => deck.name === deckName) || null;
  };
  
  // Function to create a new deck
  const createDeck = (deckName) => {
    const deckBox = getDeckBox();
  
    if (deckBox.some((deck) => deck.name === deckName)) {
      throw new Error("A deck with this name already exists.");
    }
  
    const newDeck = {
      name: deckName,
      creationDate: new Date().toISOString(),
      cards: [],
    };
  
    deckBox.push(newDeck);
    saveDeckBox(deckBox);
  
    return newDeck;
  };
  
  // Function to delete a deck by its name
  const deleteDeck = (deckName) => {
    let deckBox = getDeckBox();
  
    deckBox = deckBox.filter((deck) => deck.name !== deckName);
    saveDeckBox(deckBox);
  };
  
  // Function to rename a deck
  const renameDeck = (oldName, newName) => {
    const deckBox = getDeckBox();
  
    if (deckBox.some((deck) => deck.name === newName)) {
      throw new Error("A deck with this name already exists.");
    }
  
    const deck = deckBox.find((deck) => deck.name === oldName);
    if (!deck) {
      throw new Error("Deck not found.");
    }
  
    deck.name = newName;
    saveDeckBox(deckBox);
  };
  
  export { getDeckBox, saveDeckBox, getDeckByName, createDeck, deleteDeck, renameDeck };
  