<!-- ./components/HeaderComponent.vue -->
<template>
  <header class="sticky-header no-print">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Card Builder</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a 
                class="nav-link" 
                href="#" 
                @click.prevent="$emit('show-card-list')"
              >
                Card List
              </a>
            </li>
            <li class="nav-item">
              <a 
                class="nav-link" 
                href="#" 
                @click.prevent="$emit('show-deck-builder')"
              >
                Decks
              </a>
            </li>
            <li class="nav-item">
              <a 
                class="nav-link" 
                href="#" 
                @click.prevent="$emit('show-print')"
              >
                Print
              </a>
            </li>
          </ul>
          <!-- Selected Deck Name -->
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" v-if="selectedDeck">
              <span class="nav-link disabled">
                Selected Deck: {{ selectedDeck.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { inject } from "vue";

export default {
  name: "HeaderComponent",
  setup() {
    const selectedDeck = inject("selectedDeck"); // Inject the selectedDeck
    return { selectedDeck };
  },
};
</script>
