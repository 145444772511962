<!-- ./components/CardDetailComponent.vue -->
<template>
  <div class="card display-card" :style="cardStyle">
    <div class="row h-100">
      <div class="col-7mm d-flex flex-column">
        <div class="card-body no-space flex-grow-1">
          <!-- Top transparent card -->
          <div class="card transparent-card">
            <!-- Cost circles -->
            <div class="d-flex flex-wrap justify-content-center align-items-center">
              <div v-for="(costPiece, index) in card.CostSplit" :key="index">
                <template v-if="isNumberCost(costPiece)">
                  <!-- Number Cost: Display corresponding numbered symbol -->
                  <img :src="getNumberCostImagePath(costPiece)" :alt="costPiece" class="cost-image" />
                </template>
                <template v-else>
                  <!-- Non-Number Cost: Display corresponding element symbol -->
                  <img :src="getCostImagePath(costPiece)" :alt="costPiece" class="cost-image" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- Bottom transparent card -->
        <div class="card transparent-card no-space text-center d-flex flex-column align-items-center"
          v-if="card.attack !== null">
          <!-- Combat card stats -->
          <h5 class="combat-stat"><i class="bi bi-chevron-double-up"></i> {{ card.attack }} </h5>
          <hr class="stat-divider" />
          <h5 class="combat-stat">{{ card.health }} <i class="bi bi-suit-heart"></i></h5>
        </div>
      </div>
      <div class="col">
        <div class="card-body no-space">
          <!-- Sub-card for the card name -->
          <div class="card transparent-card card-title-container justify-content-center">
            <h5 ref="cardTitle" :class="cardTitleClass">
              <i class="bi bi-lightning-charge-fill" v-if="card.IsInterruptInd"></i> {{ card.name }}
            </h5>
          </div>
          <div class="text-outline card-type-text ">
            {{ card.TypeDisplay }} <span v-if="card.SubtypeDisplay">-</span> {{ card.SubtypeDisplay }}
          </div>
          <!-- Card image -->
          <div class="card-image">
            <!-- <img v-if="cardImageExists" :src="cardImagePath" alt="Card Art" /> -->
          </div>
          <div class="card-rules" v-html="parsedRulesText"></div>
          <div class="card-provides">
            <div v-for="(costPiece, index) in card.Provides" :key="index">
              <img :src="getCostImagePath(costPiece)" :alt="costPiece" class="cost-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDetailComponent",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cardImageExists: false,
      cardImagePath: `/art/${this.card.name}.jpg`,
    };
  },
  computed: {
    cardStyle() {
      const elementColors = {
        Neutral: "#cccccc",
        Abyssal: "#45818e",
        Arcane: "#bd94a8",
        Force: "#dd7e6b",
        Light: "#f1c232",
        Noxious: "#8e7cc3",
        Storm: "#b6d7a8",
        Terra: "#f9cb9c",
        Thermal: "#6d9eeb",
      };

      const elements = this.card.element || [];
      let baseStyle = {
        width: "63mm",
        height: "86mm",
      };

      if (elements.length === 1) {
        baseStyle.backgroundColor = elementColors[elements[0]];
      } else if (elements.length > 1) {
        const gradientColors = elements
          .map((element) => elementColors[element] || "#ffffff")
          .join(", ");
        baseStyle.backgroundImage = `linear-gradient(to right, ${gradientColors})`;
      }

      if (this.card.IsEtherealInd) {
        const verticalGradient = "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 50%)";
        baseStyle.backgroundImage = baseStyle.backgroundImage
          ? `${baseStyle.backgroundImage}, ${verticalGradient}`
          : verticalGradient;
      }

      return baseStyle;
    },
    cardTitleClass() {
      return this.card.isEpic ? "text-outline-epic card-title" : "text-outline card-title";
    },

    parsedRulesText() {
      const elementMapping = {
        A: "Abyssal",
        R: "Arcane",
        F: "Force",
        L: "Light",
        N: "Noxious",
        S: "Storm",
        E: "Terra",
        T: "Thermal",
      };

      // Replace [E] tags with <img> for corresponding PNG symbols
      const transformElementTags = (text) => {
        // Handle element symbols [A], [R], etc.
        text = text.replace(/\[([ARFLNSET])\]/g, (match, elementKey) => {
          const elementName = elementMapping[elementKey];
          const imgSrc = elementName
            ? `/symbol/Symbol${elementName}.png`
            : `/symbol/SymbolNeutral.png`; // Fallback for unmapped elements

          return `<img src="${imgSrc}" alt="${elementKey}" class="rules-cost-image" />`;
        });

        // Handle number symbols [0], [10], [-1], etc.
        text = text.replace(/\[(-?\d+)\]/g, (match, number) => {
          const imgSrc = `/symbol/Symbol${number}.png`;
          return `<img src="${imgSrc}" alt="${number}" class="rules-cost-image" />`;
        });

        return text;
      };

      // Replace newlines with <br> and apply element transformation
      const textWithNewlines = this.card.RulesTextDisplay.replace(/\n/g, '<br>');
      return transformElementTags(textWithNewlines);
    },


  },
  methods: {
    async checkImageExists(imagePath) {
      try {
        const response = await fetch(imagePath, { method: "HEAD" });
        return response.ok;
      } catch {
        return false;
      }
    },
    darkenColor(color, amount = 20) {
      const colorHex = color.startsWith("#") ? color.slice(1) : color;
      const rgb = parseInt(colorHex, 16);
      const r = Math.max((rgb >> 16) - amount, 0);
      const g = Math.max(((rgb >> 8) & 0x00ff) - amount, 0);
      const b = Math.max((rgb & 0x0000ff) - amount, 0);
      return `rgb(${r}, ${g}, ${b})`;
    },
    isNumberCost(costPiece) {
      // Check if the cost is a number (including 0) or the special 'X' case
      return !isNaN(parseInt(costPiece, 10)) || costPiece === "X";
    },
    getNumberCostImagePath(costPiece) {
      // Load numbered symbol image for number costs or 'X'
      if (costPiece === "X") {
        return `/symbol/SymbolX.png`; // Special case for 'X'
      }

      const number = parseInt(costPiece, 10);
      if (number >= 0 && number <= 12) {
        return `/symbol/Symbol${number}.png`;
      }

      return `/symbol/SymbolNeutral.png`; // Fallback for out-of-range numbers
    },
    getCostImagePath(costPiece) {
      // Load element symbol image for element costs
      const elementMapping = {
        A: "Abyssal",
        R: "Arcane",
        F: "Force",
        L: "Light",
        N: "Noxious",
        S: "Storm",
        E: "Terra",
        T: "Thermal",
      };

      if (costPiece === "X") {
        return `/symbol/SymbolX.png`; // Special case for 'X'
      }

      const elementName = elementMapping[costPiece.toUpperCase()];
      return elementName
        ? `/symbol/Symbol${elementName}.png`
        : `/symbol/SymbolNeutral.png`; // Fallback for unmapped elements
    },
    getCostCircleStyle(costPiece) {
      const elementColors = {
        Neutral: "#cccccc",
        Abyssal: "#45818e",
        Arcane: "#bd94a8",
        Force: "#dd7e6b",
        Light: "#f1c232",
        Noxious: "#8e7cc3",
        Storm: "#b6d7a8",
        Terra: "#f9cb9c",
        Thermal: "#6d9eeb",
      };

      const defaultColor = elementColors.Neutral;

      const elementMapping = {
        A: "Abyssal",
        R: "Arcane",
        F: "Force",
        L: "Light",
        N: "Noxious",
        S: "Storm",
        E: "Terra",
        T: "Thermal",
      };

      const elementKey = costPiece.toUpperCase();
      const baseColor =
        elementMapping[elementKey] && this.card.element.includes(elementMapping[elementKey])
          ? elementColors[elementMapping[elementKey]]
          : defaultColor;

      const darkenedColor = this.darkenColor(baseColor, 30);

      return {
        backgroundColor: darkenedColor,
        width: "5mm",
        height: "5mm",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "2px",
      };
    },
  },
  async mounted() {
    this.cardImageExists = await this.checkImageExists(this.cardImagePath);
  },
};
</script>
