<!-- ./components/PrintComponent.vue -->
<template>
  <div class="no-print">
    <div class="row text-center">
      <button class="btn btn-primary" @click="downloadCardImages">
        Download Card Images
      </button>
    </div>
  </div>
  <div class="print-component">
    <div class="row">
      <div class="col-auto" v-for="(card, index) in expandedCardsToPrint" :key="index">
        <!-- Render each card for display -->
        <CardDetailComponent ref="cardComponents" :card="card" />
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed, ref } from "vue";
import CardDetailComponent from "./CardDetailComponent.vue";
import html2canvas from "html2canvas"; // Import html2canvas

export default {
  name: "PrintComponent",
  components: {
    CardDetailComponent,
  },
  setup() {
    const selectedDeck = inject("selectedDeck");
    const cardData = inject("cardData");
    const getCardData = inject("getCardData");
    const cardComponents = ref([]);

    // Determine which cards to display for printing
    const cardsToPrint = computed(() => {
      if (selectedDeck && selectedDeck.value) {
        return getCardData(selectedDeck.value.cards);
      }
      return cardData;
    });

    // Expand cards based on their 'count' value
    const expandedCardsToPrint = computed(() => {
      return cardsToPrint.value.flatMap((card) =>
        Array(card.count || 1).fill(card)
      );
    });

    // Function to download card images
    const downloadCardImages = async () => {
      for (let i = 0; i < expandedCardsToPrint.value.length; i++) {
        const card = expandedCardsToPrint.value[i];
        const cardComponent = cardComponents.value[i];

        // Wait for the CardDetailComponent to render
        const element = cardComponent?.$el;
        if (element) {
          const canvas = await html2canvas(element, {
            scale: 3, // Increase scale to improve image resolution
            useCORS: true, // Ensures cross-origin images load properly
          });

          const link = document.createElement("a");
          link.download = `01x ${card.name}.png`;
          link.href = canvas.toDataURL("image/png", 1.0); // Ensure maximum image quality
          link.click();
        }
      }
    };


    return {
      expandedCardsToPrint,
      cardComponents,
      downloadCardImages,
    };
  },
};
</script>
